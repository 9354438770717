<template>
  <div>
    <nav-bar rightShow="display: none;">
      <template v-slot:left>
        <div class="logo" @click="toIndex">
          <nav-bar-logo/>
        </div>
      </template>
    </nav-bar>
    <login-banner/>
    <login-footer/>
  </div>
</template>

<script>
import NavBar from 'components/navbar/NavBar.vue'
import NavBarLogo from 'components/navbar/NavBarLogo.vue'
import LoginBanner from 'views/login/childComps/LoginBanner.vue'
import LoginFooter from 'views/login/childComps/LoginFooter.vue'

export default {
  components: {
    NavBar,
    NavBarLogo,
    LoginBanner,
    LoginFooter
  },
  methods: {
    toIndex () {
      this.$router.push('index')
    }
  },
  mounted () {
    console.log(window.history)
    const that = this
    window.addEventListener('popstate', function (e) {
      that.$router.push('/index')
    }, false)
  }
}
</script>

<style scoped src="styles/views/login/login.css">
</style>
