<template>
  <div class="container-full banner">
    <div class="container content">
      <div class="content-l">
        <div class="content-l-t">
          <div>开通分站</div>
          <div>拥有属于自己的网站</div>
        </div>
        <div class="content-l-b">独立后台自由管理 · 24小时自动售单 · 无需人工干预</div>
      </div>
      <div class="content-r" @keydown="keyLogin">
        <div class="content-r-t">
          <span @click="accountLogin" class="number" :class="{'title-active': loginMode}">账号登录</span>
          <span @click="smsLogin" class="message" :class="{'title-active': !loginMode}">短信登录</span>
        </div>
        <div>
          <!-- 账号登录 -->
          <sign-up-input v-if="loginMode" @form-data="getFormData"/>
          <!-- 短信登录 -->
          <sign-up-input v-else :numOrSms="false" @form-data="getFormData" @get-code="getCode"/>
          <div style="font-size: 12px;color: #888;text-indent: 5px;margin-top: -10px;"><el-checkbox v-model="checked"></el-checkbox><span style="margin-left: 5px;">勾选即同意该<a href="/xieyi" target="_blank" style="color: #409EFF;">《用户使用协议》</a></span></div>
          <!-- 登录 -->
            <div class="btn-box">
              <el-button class="btn" @click="login">登录</el-button>
            </div>
          <div class="actions_item">
            <router-link to="/register">立即注册</router-link>
            <span v-show="loginMode == true">|</span>
            <router-link to="/rePwd" v-show="loginMode == true">忘记密码</router-link>
            <!-- <router-link to="/rePwd" v-show="loginMode == true">忘记密码</router-link> -->
          </div>
        </div>
      </div>
      <div style="height: 100%;display: flex;align-items: center;">
        <div class="dialog" style="margin-left: 20px;display: flex;align-items: center;flex-direction: column;justify-content: center;">
          <div style="display: flex;align-items: center;margin-bottom: 10px;width: 150px;">
            <div style="margin-right: 10px;"><svg t="1699840659044" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1468" width="32" height="32"><path d="M885.333333 480h-70.4v-196.266667c-36.266667-104.533333-125.866667-194.133333-230.4-217.6-106.666667-23.466667-217.6 8.533333-294.4 85.333334-34.133333 34.133333-64 83.2-78.933333 132.266666v196.266667H138.666667C108.8 480 85.333333 456.533333 85.333333 426.666667v-100.266667c0-29.866667 25.6-55.466667 55.466667-55.466667h12.8c17.066667-59.733333 51.2-115.2 93.866667-160C315.733333 42.666667 409.6 0 512 0c166.4 0 311.466667 110.933333 360.533333 270.933333h10.666667c29.866667 0 55.466667 25.6 55.466667 55.466667V426.666667c0 29.866667-23.466667 53.333333-53.333334 53.333333zM407.466667 618.666667c-96-40.533333-162.133333-134.4-162.133334-245.333334C245.333333 226.133333 364.8 106.666667 512 106.666667s266.666667 119.466667 266.666667 266.666666c0 10.666667 0 19.2-2.133334 27.733334-32 14.933333-64 25.6-98.133333 34.133333-32 6.4-64 12.8-96 14.933333-8.533333-14.933333-25.6-25.6-42.666667-25.6h-53.333333c-25.6 0-46.933333 21.333333-46.933333 46.933334 0 25.6 21.333333 46.933333 46.933333 46.933333h53.333333c19.2 0 36.266667-10.666667 44.8-27.733333 34.133333-2.133333 68.266667-8.533333 102.4-14.933334 27.733333-6.4 53.333333-14.933333 76.8-23.466666-23.466667 74.666667-78.933333 136.533333-151.466666 166.4 206.933333 8.533333 390.4 136.533333 390.4 405.333333H21.333333c0-268.8 181.333333-396.8 386.133334-405.333333z" fill="#FF9E66" p-id="1469"></path></svg></div>
            <span>扫码联系客服</span>
          </div>
          <img :src="$store.getters.getSiteInfo.wechat_service" width="100" height="100"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignUpInput from 'components/signUpInput/SignUpInput.vue'
import { login, mobilelogin, seachLog, getUserMoney } from 'network/api'

export default {
  components: {
    SignUpInput
  },
  data () {
    return {
      loginMode: true, // 账号登录为true,短信登录为false
      checked: true,
      formData: {
        account: '',
        password: '',
        code: '',
        accountBoole: null,
        passwordBoole: null,
        codeBoole: null,
        clickRepeat: true
      }
    }
  },
  mounted () {
    // const _this = this
    // if (!sessionStorage.getItem('userInfo')) {
    //   document.onkeydown = function (e) {
    //     e = e || window.e
    //     if (e.keyCode === 13) {
    //       _this.login()
    //     }
    //   }
    // }
  },
  methods: {
    accountLogin () {
      this.loginMode = true
    },
    smsLogin () {
      this.loginMode = false
    },
    getFormData (data) {
      this.formData = data
    },
    keyLogin (e) {
      if (this.formData.account && !localStorage.getItem('userInfo')) {
        if (e.keyCode === 13) {
          this.login()
        }
      }
    },
    getUUID () {
      var s = []
      var hexDigits = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRETUVWXYZ'
      for (var i = 0; i < 25; i++) {
        s[i] = hexDigits[Math.floor(Math.random() * 0x40)]
      }
      s[12] = s[20] = '_'
      var uuid = s.join('')
      return uuid
    },
    // 登录
    login () {
      if (!this.checked) {
        this.$message.error('请先阅读并同意本站用户使用协议')
        return
      }
      if (this.loginMode) {
        login(this.formData.account, this.formData.password).then(res => {
          if (res.code === 1) { // 登录成功
            this.$notify({
              title: '欢迎',
              message: res.msg,
              type: 'success'
            })
            // 存储登录信息
            const userInfo = res.data.userinfo
            sessionStorage.setItem('lpwUserInfo', JSON.stringify(userInfo))
            localStorage.setItem('lpwUserInfo', JSON.stringify(userInfo))
            localStorage.setItem('userInfo', JSON.stringify(userInfo))
            this.$store.dispatch('login', userInfo)
            getUserMoney().then(res => {
              localStorage.setItem('userInfo', JSON.stringify({
                ...userInfo,
                ...res.data
              }))
            })
            // 写入唯一标识s
            let UUID = ''
            if (!localStorage.getItem('setOnly')) {
              UUID = this.getUUID()
              localStorage.setItem('setOnly', UUID)
            } else {
              UUID = localStorage.getItem('setOnly')
            }
            seachLog({ urlType: UUID, remark: 'setOnly' }).then(() => {})
            // 跳转
            const _this = this
            setTimeout(function () {
              _this.$router.push('/orderAdmin/purchase')
            }, 1000)
          } else if (res.code === 0) { // 登录失败
            this.$notify.error({
              title: '错误',
              message: res.msg
            })
          }
        })
      } else {
        if (this.clickRepeat === false) return
        this.clickRepeat = false
        mobilelogin(this.formData.account, this.formData.code, this.formData.prPhone).then(res => {
          if (res.code === 1) { // 登录成功
            this.$notify({
              title: '欢迎',
              message: res.msg,
              type: 'success'
            })
            // 存储登录信息
            const userInfo = res.data.userinfo
            sessionStorage.setItem('lpwUserInfo', JSON.stringify(userInfo))
            this.$store.dispatch('login', userInfo)
            // 跳转
            const _this = this
            setTimeout(function () {
              _this.$router.push('/orderAdmin/purchase')
            }, 2000)
          } else if (res.code === 0) { // 登录失败
            this.$notify.error({
              title: '错误',
              message: res.msg
            })
          }
          this.clickRepeat = true
        })
      }
    },
    // 获取验证码
    getCode () {}
  }
}
</script>

<style scoped src="styles/views/login/loginbanner.css">
</style>
